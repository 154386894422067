<!-- 客户用量表 -->
<template>
  <div class="page">
    <div class="topDiv">
      <Form inline class="form formMarginBtm20" ref="dataForm" :model="searchForm" :loading="loading">
        <FormItem prop="usage_name">
          <span class="label">用量表名称：</span>
          <Select class="iviewIptWidth250 marginRight70" v-model="searchForm.meter_name" clearable filterable placeholder="请输入">
            <Option v-for="(item, index) in usageNameSelect" :value="item.meter_name" :key="index">{{ item.meter_name }}</Option>
          </Select>
        </FormItem>
        <FormItem prop="customer">
          <span class="label">所属客户：</span>
          <Select class="iviewIptWidth250 marginRight70" v-model="searchForm.customer_id" clearable filterable>
            <Option v-for="(item, index) in customerSelect" :value="item.customer_id" :key="index">{{ item.customer_name }}</Option>
          </Select>
        </FormItem>
        <FormItem prop="timeArray">
          <span class="label">接收时间：</span>
          <DatePicker type="date" class="iviewIptWidth250" placeholder="请选择" @on-change="changeTime($event, 1)" clearable></DatePicker>
          <span class="timeDivide">至</span>
          <DatePicker type="date" class="iviewIptWidth250" placeholder="请选择" v-model="searchForm.end_time_end" :options="options" @on-change="changeTime($event, 2)" clearable></DatePicker>
        </FormItem>
        <!-- <FormItem prop="usage_type">
          <span class="label">用量表类型：</span>
          <Select class="iviewIptWidth250 marginRight70" v-model="searchForm.meter_category" clearable>
            <Option v-for="(item, index) in usageTypeSelect" :value="item.meter_category" :key="index">{{ item.usageType }}</Option>
          </Select>
        </FormItem> -->
        <FormItem class="po-create-number">
          <span class="pageBtn finger btnSure marginLeft30" @click="searchUsageList">查询</span>
        </FormItem>
      </Form>
    </div>
    <ul class="topNavDiv">
      <li v-for="(item, index) in navFrist" :key="index" :class="{ clickActive: clickIndex == item.type, finger: true }" @click="clickNav(item.type)">
        {{ item.name }}
        <div class="linear" v-if="clickIndex == item.type"></div>
      </li>
    </ul>
    <div class="tabelDiv">
      <Table :productList="listColumns" :productData="listData" :pages="pages" @change-page="changePage" :isLoad="isLoad" totalText="条记录" :total="total"> </Table>
    </div>
    <Modal v-model="postModel" label-position="left" width="400" :footer-hide="true" :closable="false" class-name="vertical-center-modal">
      <div class="reviewDiv">
        <p>是否确认推送？</p>
        <div>
          <span class="pageBtn finger btnSure" @click="changeStatus">确定</span>
          <span class="pageBtn finger btnCancle" @click="postModel = false">取消</span>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import Table from '@/components/table'
import { throttle } from '../../service/utils'
export default {
  name: 'productManage',
  components: {
    Table,
  },
  computed: {
    totleMoney() {
      let totle = 0
      this.listData.forEach((e, i) => {
        totle = totle * 1 + e.money * 1
      })
      return totle
    },
  },
  data() {
    return {
      isLoad: false,
      usageTypeList: [
        {
          value: '0',
          label: '委托代销',
        },
        {
          value: '1',
          label: '自寄售',
        },
        {
          value: '2',
          label: '普通采购',
        },
        {
          value: '3',
          label: '供方寄售',
        },
      ],
      navFrist: [
        {
          name: '总表',
          type: 1,
        },
        {
          name: '委托代销',
          type: 2,
        },
        {
          name: '自寄售',
          type: 3,
        },
        {
          name: '供方寄售',
          type: 4,
        },
      ],
      clickIndex: 1,
      /* 显示表头 */
      listColumns: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          minWidth: 75,
          width: 75,
        },
        {
          title: '用量表名称',
          align: 'center',
          key: 'meter_name',
          render: (h, param) => {
            return h(
              'span',
              {
                class: 'tableFont',
                on: {
                  click: () => {
                    let type = 1
                    let customer_meter_id = param.row.id
                    this.$router.push({ path: 'customerScaleDetail', query: { type, customer_meter_id } })
                  },
                },
              },
              param.row.meter_name
            )
          },
          minWidth: 160,
        },
        {
          title: '所属客户',
          key: 'customer_name',
          align: 'center',
          minWidth: 300,
        },
        {
          title: '用量表类型',
          render(h, params) {
            let str = params.row.meter_category
            switch (str) {
              case '0':
                return h('span', '委托代销')
              case '1':
                return h('span', '自寄售')
              case '2':
                return h('span', '供方寄售')
              default:
                break
            }
            return str
          },
          align: 'center',
          minWidth: 120,
        },
        {
          title: '接收时间',
          key: 'insert_time',
          align: 'center',
          minWidth: 120,
        },
        {
          title: '操作',
          align: 'center',
          render: (h, param) => {
            return h('div', [
              h(
                'span',
                {
                  /* style: {
                    color: '#389AFC',
                    fontSize: '14px',
                    cursor: 'pointer',
                  }, */
                  class: 'tableFont',
                  on: {
                    click: () => {
                      let meter_category = '3'
                      let customer_meter_id = param.row.id
                      this.$router.push({ path: 'customerScaleDetail', query: { meter_category, customer_meter_id } })
                    },
                  },
                },
                '详情'
              ),
              h(
                'span',
                {
                  style: {
                    /* color: '#389AFC',
                    fontSize: '14px',
                    cursor: 'pointer', */
                    marginLeft: '30px',
                  },
                  // class: 'tableFont',
                  class: {
                        tableFont:true,
                        disableUse:this.searchAble,
                      },
                  on: {
                    click: () => {
                      this.output()
                    },
                  },
                },
                '导出'
              ),
            ])
          },
          minWidth: 212,
        },
      ],
      // 总表表头
      listColumns1: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          minWidth: 75,
          width: 75,
        },
        {
          title: '用量表名称',
          align: 'center',
          key: 'meter_name',
          render: (h, param) => {
            return h(
              'span',
              {
                class: 'tableFont',
                on: {
                  click: () => {
                    let type = 1
                    let customer_meter_id = param.row.id
                    this.$router.push({ path: 'customerScaleDetail', query: { type, customer_meter_id } })
                  },
                },
              },
              param.row.meter_name
            )
          },
          minWidth: 160,
        },
        {
          title: '所属客户',
          key: 'customer_name',
          align: 'center',
          minWidth: 300,
        },
        {
          title: '用量表类型',
          align: 'center',
          minWidth: 120,
          render(h, params) {
            let str = params.row.meter_category
            switch (str) {
              case '0':
                return h('span', '委托代销')
              case '1':
                return h('span', '自寄售')
              case '2':
                return h('span', '供方寄售')
              default:
                break
            }
          },
        },
        {
          title: '接收时间',
          key: 'insert_time',
          align: 'center',
          minWidth: 120,
        },
        {
          title: '操作',
          align: 'center',
          render: (h, param) => {
            return h('div', [
              h(
                'span',
                {
                  /* style: {
                    color: '#389AFC',
                    fontSize: '14px',
                    cursor: 'pointer',
                  }, */
                  class: 'tableFont',
                  on: {
                    click: () => {
                      let meter_category = '3'
                      let customer_meter_id = param.row.id
                      this.$router.push({ path: 'customerScaleDetail', query: { meter_category, customer_meter_id } })
                    },
                  },
                },
                '详情'
              ),
              h(
                'span',
                {
                  style: {
                    /* color: '#389AFC',
                    fontSize: '14px',
                    cursor: 'pointer', */
                    marginLeft: '30px',
                  },
                  class: 'tableFont',
                  on: {
                    click: () => {
                      this.output()
                    },
                  },
                },
                '导出'
              ),
            ])
          },
          minWidth: 212,
        },
      ],
      // 委托代销、自寄售表头
      listColumns2: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          minWidth: 75,
          width: 75,
        },
        {
          title: '用量表名称',
          align: 'center',
          key: 'meter_name',
          render: (h, param) => {
            return h(
              'span',
              {
                class: 'tableFont',
                on: {
                  click: () => {
                    let meter_category = param.row.meter_category
                    let type = 0
                    // 委托代销
                    if (meter_category === '0') {
                      type = 2
                    }
                    // 自寄售
                    if (meter_category === '1') {
                      type = 3
                    }
                    let customer_meter_id = param.row.id
                    this.$router.push({ path: 'customerScaleDetail', query: { type, customer_meter_id } })
                  },
                },
              },
              param.row.meter_name
            )
          },
          minWidth: 160,
        },
        {
          title: '所属客户',
          key: 'customer_name',
          align: 'center',
          minWidth: 160,
        },
        {
          title: '供应商',
          key: 'supplier_name',
          align: 'center',
          minWidth: 220,
        },
        {
          title: '月份',
          key: 'month',
          align: 'center',
          minWidth: 75,
        },
        {
          title: '产品名称',
          key: 'product_name_list',
          align: 'center',
          minWidth: 185,
        },
        {
          title: '数量',
          key: 'quantity',
          align: 'center',
          minWidth: 75,
        },
        {
          title: '状态',
          key: 'meter_status_str',
          align: 'center',
          minWidth: 90,
        },
        {
          title: '操作',
          align: 'center',
          render: (h, param) => {
            let str = param.row.meter_status_str
            switch (str) {
              case '待补充':
                return h('div', [
                  h(
                    'span',
                    {
                      // class: 'tableFont marginLeft30',
                      class: {
                        tableFont: true,
                        marginLeft30: true,
                        disableUse: this.insertAble,
                      },
                      on: {
                        click: () => {
                          let customer_meter_id = param.row.id
                          let meter_category = param.row.meter_category
                          this.$router.push({ path: 'customerScaleAdd', query: { meter_category, customer_meter_id } })
                        },
                      },
                    },
                    '补充'
                  ),
                ])
              case '待转单':
                return h('div', [
                  h(
                    'span',
                    {
                      // class: 'tableFont',
                      class: {
                        tableFont: true,
                        disableUse: this.modifyAble,
                      },
                      on: {
                        click: () => {
                          let meter_category = param.row.meter_category
                          let type = 0
                          let edit = 1 // 编辑
                          // 委托代销
                          if (meter_category === '0') {
                            type = 2
                          }
                          // 自寄售
                          if (meter_category === '1') {
                            type = 3
                          }
                          let customer_meter_id = param.row.id
                          this.$router.push({ path: 'customerScaleDetail', query: { type, edit, customer_meter_id } })
                        },
                      },
                    },
                    '编辑'
                  ),
                ])
              case '已转单':
                return h('div', [])
              default:
                break
            }
          },
          minWidth: 174,
        },
      ],
      // 供方寄售表头
      listColumns3: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          minWidth: 75,
          width: 75,
        },
        {
          title: '用量表名称',
          align: 'center',
          key: 'meter_name',
          render: (h, param) => {
            return h(
              'span',
              {
                class: 'tableFont',
                on: {
                  click: () => {
                    let type = 4
                    let customer_meter_id = param.row.id
                    this.$router.push({ path: 'customerScaleDetail', query: { type, customer_meter_id } })
                  },
                },
              },
              param.row.meter_name
            )
          },
          minWidth: 160,
        },
        {
          title: '所属客户',
          key: 'customer_name',
          align: 'center',
          minWidth: 160,
        },
        {
          title: '供应商',
          key: 'supplier_name',
          align: 'center',
          minWidth: 215,
        },
        {
          title: '月份',
          key: 'month',
          align: 'center',
          minWidth: 80,
        },
        {
          title: '产品名称',
          key: 'product_name_list',
          align: 'center',
          minWidth: 220,
        },
        {
          title: '数量',
          key: 'quantity',
          align: 'center',
          minWidth: 80,
        },
        {
          title: '状态',
          key: 'meter_status_str',
          align: 'center',
          minWidth: 90,
        },
        {
          title: '操作',
          align: 'center',
          render: (h, param) => {
            let str = param.row.meter_status_str
            switch (str) {
              case '待推送':
                return h('div', [
                  h(
                    'span',
                    {
                      /* style: {
                        color: '#389AFC',
                        fontSize: '14px',
                        cursor: 'pointer',
                      }, */
                      // class: 'tableFont',
                      class: {
                        tableFont:true,
                        disableUse:this.modifyAble,
                      },
                      on: {
                        click: () => {
                          this.postModel = true
                          this.post_id = param.row.id
                        },
                      },
                    },
                    '推送'
                  ),
                ])
              case '待补充':
                return h('div', [
                  h(
                    'span',
                    {
                      /* style: {
                        color: '#389AFC',
                        fontSize: '14px',
                        cursor: 'pointer',
                      }, */
                      // class: 'tableFont',
                      class: {
                        tableFont: true,
                        marginLeft30: true,
                        disableUse: this.insertAble,
                      },
                      on: {
                        click: () => {
                          let customer_meter_id = param.row.id
                          let meter_category = param.row.meter_category
                          this.$router.push({ path: 'customerScaleAdd', query: { meter_category, customer_meter_id } })
                        },
                      },
                    },
                    '补充'
                  ),
                ])
              case '已补充':
                return h('div', [
                  h(
                    'span',
                    {
                      class: {
                        tableFont: true,
                        disableUse: this.modifyAble,
                      },
                      on: {
                        click: () => {
                          let type = 4
                          let edit = 1 // 编辑
                          let customer_meter_id = param.row.id
                          this.$router.push({ path: 'customerScaleDetail', query: { type, edit, customer_meter_id } })
                        },
                      },
                    },
                    '转单'
                  ),
                ])
              case '已完成':
                return h('div', [
                  h(
                    'span',
                    {
                      /* style: {
                        color: '#389AFC',
                        fontSize: '14px',
                        cursor: 'pointer',
                      }, */
                      // class: 'tableFont',
                      class: {
                        tableFont:true,
                        disableUse:this.searchAble,
                      },
                      on: {
                        click: () => {
                          this.output()
                        },
                      },
                    },
                    '导出'
                  ),
                ])
              default:
                break
            }
          },
          minWidth: 174,
        },
      ],
      listData: [],
      totalList: [],
      usageNameSelect: [], // 用量表下拉
      customerSelect: [], // 所属客户下拉
      usageTypeSelect: [
        {
          usageType: '委托代销',
          meter_category: '0',
        },
        {
          usageType: '自寄售',
          meter_category: '1',
        },
        {
          usageType: '供方寄售',
          meter_category: '2',
        },
      ], // 用量表类型下拉
      loading: false,
      searchForm: {
        meter_name: '',
        customer_id: '',
        customer_type: '',
        meter_category: '',
        timeArray: [],
        end_time_start: '',
        end_time_end: '',
      },
      total: 28,
      pages: {
        page: 1,
        rows: 10,
      },
      postModel: false /* 推送模态框状态 */,
      post_id: 0 /* 推送id */,
      options: {} /* 时间设置 */,
      meter_category: '0', // 用量表类型
      // 以下是权限按钮显示
      deleteAble: false, // 删除权限
      insertAble: false, // 插入权限
      modifyAble: false, // 修改权限
      searchAble: false, // 查找权限
    }
  },
  methods: {
    // 点击审核
    review(row) {
      this.$router.push({
        name: 'productFrame',
        query: {
          reviewId: row.review_id,
          status: 2,
        },
      })
    },
    addOrder() {},
    changePage(e) {
      this.pages.page = e
      this.getTableList(this.pages, this.searchForm)
    },
    /* 点击tabs事件 */
    clickNav(type) {
      this.clickIndex = type
      if (type == 1) {
        // 总表
        this.searchForm.meter_category = ''
        this.listColumns = this.listColumns1
        this.pages = { page: 1, rows: 10 }
        this.getTableList(this.pages, { meter_category: '' })
      }
      if (type == 2) {
        // 委托代销
        this.searchForm.meter_category = '0'
        this.listColumns = this.listColumns2
        this.pages = { page: 1, rows: 10 }
        this.getTableList(this.pages, { meter_category: '0' })
      }
      if (type == 3) {
        // 自寄售
        this.searchForm.meter_category = '1'
        this.listColumns = this.listColumns2
        this.pages = { page: 1, rows: 10 }
        this.getTableList(this.pages, { meter_category: '1' })
      }
      if (type == 4) {
        // 供方寄售
        this.listColumns = this.listColumns3
        this.searchForm.meter_category = '2'
        this.pages = { page: 1, rows: 10 }
        this.getTableList(this.pages, { meter_category: '2' })
      }
    },
    /* 获取客户用量表列表 */
    getTableList(pages, item) {
      let obj = {}
      Object.assign(obj, pages, item)
      this.$http.get(this.$api.customerUsageList, obj, true).then(res => {
        if (res.status) {
          for (const item of res.data) {
            item.insert_time = item.insert_time ? this.$moment.unix(item.insert_time).format('YYYY-MM-DD HH:mm:ss') : ''
          }
          // console.log(res.data)
          this.total = res.total
          this.listData = res.data
        }
      })
    },
    /* 获取用量表搜索条件下拉列表 */
    getSelect() {
      /* 获取所属客户下拉 */
      this.$http.get(this.$api.customerList, true).then(res => {
        if (res.status) {
          this.customerSelect = res.data
        }
      })
      this.$http.get(this.$api.customerUsageNameList).then(res => {
        if (res.status) {
          this.usageNameSelect = res.data
        }
      })
    },
    /* 搜索用量表 */
    searchUsageList() {
      let start_time = this.searchForm.end_time_start ? this.$moment(this.searchForm.end_time_start).format('YYYY-MM-DD') : ''
      let end_time = this.searchForm.end_time_end ? this.$moment(this.searchForm.end_time_end).format('YYYY-MM-DD') : ''
      let meter_name = this.searchForm.meter_name
      let customer_id = this.searchForm.customer_id ? this.searchForm.customer_id : null
      let customer_type = '1'
      let meter_category = this.searchForm.meter_category ? this.searchForm.meter_category : ''
      let obj = { meter_name, customer_id, customer_type, meter_category, start_time, end_time }
      console.log(obj)
      this.getTableList({ page: 1, rows: 10 }, obj)
    },
    /* 推送 */
    changeStatus() {
      let customer_meter_id = this.post_id
      this.$http.get(this.$api.customerUsageStatusChange, { customer_meter_id }, true).then(res => {
        if (res.status) {
          this.$Message.success('推送成功')
          this.clickNav(4)
        }
      })
      this.postModel = false
    },
    // 时间改变
    changeTime(e, num) {
      if (num == 1) {
        let that = this
        that.searchForm.end_time_start = e
        that.searchForm.end_time_end = ''
        that.options = {
          disabledDate(date) {
            return date && date.valueOf() < new Date(that.searchForm.end_time_start)
          },
        }
      } else {
        this.searchForm.end_time_end = e
      }
    },
    // 导出
    output() {
      this.$Message.warning('功能还在开发中...')
    },
    // 获取按钮显示权限方法
    getRightVisibal() {
      let navName = this.$route.meta.navName
      let rightStr = JSON.parse(window.sessionStorage.getItem(navName))
      this.deleteAble = !rightStr.delete
      this.insertAble = !rightStr.insert
      this.modifyAble = !rightStr.modify
      this.searchAble = !rightStr.search
    },
  },
async  activated () {
    if(sessionStorage.getItem('updataCache') == 0) {
       await this.getTableList(this.pages, {})
       sessionStorage.setItem('updataCache','1')
    }
     
  },
  async created() {
    let flag = this.$route.query.show
    let type = this.$route.query.type
    if (flag) {
      await this.clickNav(4)
    }
    if (type) {
      await this.clickNav(type)
    }
    if (!flag && !type) {
      await this.getTableList(this.pages, {})
    }
    this.getSelect()
    this.getRightVisibal()
  },
}
</script>

<style scoped lang="less">
.page {
  background: #f0f0f0 !important;
  display: flex;
  flex-direction: column;
  .tabelDiv {
    flex: 1;
    background: #fff;
    border-radius: 0 8px 8px 8px;
    padding: 20px 25px;
  }
  .topDiv {
    padding: 20px 25px 0 25px;
    background: #fff;
    border-radius: 8px;
    margin-bottom: 20px;
    .form {
      .label {
        color: #000;
      }
      .timeDivide {
        margin: 0px 10px;
      }
      /deep/ .ivu-select-dropdown {
        &::-webkit-scrollbar {
          display: none;
        }
      }
    }
  }
  .topNavDiv {
    height: 42px;
    li {
      float: left;
      width: 100px;
      height: 42px;
      line-height: 42px;
      text-align: center;
      margin-right: 2px;
      background: #e2e2e2;
      color: #999999;
      font-size: 15px;
      border-radius: 3px 3px 0 0;
      position: relative;
    }
    .clickActive {
      background: #fff;
      color: #008cf8;
      font-weight: bold;
    }
  }
}
.marginLeft30 {
  margin-left: 30px;
}
.marginRight70 {
  margin-right: 70px;
}
.marginRight90 {
  margin-right: 90px;
}
.centeFont {
  margin: 0 10px;
}
/deep/ .disableUse {
  display: none;
}
</style>
